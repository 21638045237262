export const inspections = {
    get: {
        url: 'inspection/getAllInspection',
        method: 'GET',
    },
    getById: {
        url: "inspection/getInspectionById/",
        method: "GET",
        id: null,
        params: null,
      },
      update: {
        url: 'inspection/updateOdometer/',
        method: 'POST',
        id: null,
        data: null
    },
}